<template>
  <div class="auth-wrapper auth-v1">
    <!-- <div
      ref="carga"
      class="lds-ellipsis"
      style="display: block"
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div> -->
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-left py-7 color-head-card mb-3">
          <router-link
            to="/"
            class="ml-5 d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="40px"
              max-width="100px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <!-- <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2> -->
          </router-link>
          <v-card-text>
            <p class="text-2xl font-weight-semibold text--primary mb-0">
              Inicio de Sesión
            </p>
            <p class="mb-0 color-card-text">
              Inicie sesión con su usuario y contraseña
            </p>
          </v-card-text>
        </v-card-title>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="loginForm"
            @submit.prevent="handleFormSubmit"
          >
            <v-text-field
              name="usuario"
              type="text"
              v-model="usuario"
              outlined
              label="Usuario"
              placeholder="Usuario"
              :error-messages="errorMessages.usuario"
              :rules="[validators.required]"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              name="password"
              type="password"
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Contraseña"
              :error-messages="errorMessages.password"
              placeholder="Password"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
              :rules="[validators.required]"
              hide-details="auto"
              class="mb-2"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>
            <v-btn
              block
              color="primary"
              type="submit"
              class="mt-6"
              :loading="loading"
              :disabled="loading"
            >
              Iniciar Sesión
            </v-btn>
          </v-form>
          <br>
        </v-card-text>
        <v-select
          v-if=" sucursalActive"
          v-model="sucursal"
          :items="listsucursal"
          label="Sucursal"
          outlined
          dense
          hide-details
        ></v-select>
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-1">
          <span class="me-2">
            He olvidado mi contraseña
          </span>
          <router-link :to="{name:'auth-forgot-password'}">
            Recuperar contraseña
          </router-link>
        </v-card-text>
        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-0">
          <span class="me-2">
            No tengo permisos de acceso
          </span>
          <router-link :to="{name:'auth-register'}">
            Solicitud de acceso
          </router-link>
        </v-card-text>
        <v-snackbar
          v-model="isFormSubmittedSnackbarVisible2"
          right
          top
          :color="colorSnackbar"
          close-text="Close Alert"
        >
          {{ textSnackbar }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="isFormSubmittedSnackbarVisible2 = false"
            >
              <v-icon>{{ icons.mdiCloseThick }}</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline, mdiCloseThick } from '@mdi/js'
import { ref, getCurrentInstance } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import axios from '@axios'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'

// import DemoDialogForm from './components/dialog/demos/DemoDialogForm.vue'
import { dialogForm } from './components/dialog/demos/code'

export default {
  components: {
    // DemoDialogForm,
  },
  create() {
    console.log('hola', document.getElementById('loading-initial'))
    this.$refs.carga.hidden()
  },
  setup() {
    // Template Ref
    const loginForm = ref(null)
    const carga = ref(true)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)

    const usuario = ref('')
    const password = ref('')
    const errorMessages = ref([])
    const sucursal = ref('')
    const sucursalActive = ref(false)
    const listsucursal = ref([])
    const isFormSubmittedSnackbarVisible2 = ref(false)
    const colorSnackbar = ref('')
    const textSnackbar = ref('')
    const loading = ref(false)
    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      loading.value = true

      /*
        1. Make HTTP request to get accessToken
        2. Store received token in localStorage for future use
        3. Make another HTTP request for getting user information
        4. On successful response of user information redirect to home page

        ? We have use promise chaining to get user data from access token
        ? Promise Chaining: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#chaining
      */
      const url = `${process.env.VUE_APP_API_HOST_AUTH}/api/v1/login`
      const requestOptions = {
        method: 'POST',
        url,
        headers: {
          'Content-Type': 'application/json',
          'x-mock-match-request-body': true,
          'x-hites-insurance': `${process.env.VUE_APP_APIKEY_API}`,
        },
        data: JSON.stringify({
          usuario: usuario.value,
          clave: password.value,
        }),
        validateStatus: () => true,
        withCredentials: true,
      }
      axios(requestOptions)
        .then(response => {
          if (response.data.statusCode === 400 || response.data.statusCode === 404) {
            isFormSubmittedSnackbarVisible2.value = true
            textSnackbar.value = response.data.message
            colorSnackbar.value = 'error'
            loading.value = false
          } else {
            // const accessToken = response.data.data.token

            const dataAccess = [{ action: 'manage', subject: 'all' }]

            // ? Set access token in localStorage so axios interceptor can use it
            // Axios Interceptors: https://github.com/axios/axios#interceptors
            // localStorage.setItem('accessToken', accessToken)

            const user = response.data.data
            const userAbility = dataAccess

            // Set user ability
            // ? https://casl.js.org/v5/en/guide/intro#update-rules
            vm.$ability.update(userAbility)
            if (response.data.statusCode === 201 && response.data.success === true) {
              // Set user's ability in localStorage for Access Control
              localStorage.setItem('userAbility', JSON.stringify(userAbility))

              // We will store `userAbility` in localStorage separate from userData
              // Hence, we are just removing it from user object
              delete user.ability

              // Set user's data in localStorage for UI/Other purpose
              localStorage.setItem('userData', JSON.stringify(user))
              const sucursal = []
              localStorage.setItem('userSucursal', JSON.stringify(sucursal))

              router.push('/')

              return response
            }
          }

          return false
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          errorMessages.value = error.response.data.message
          isFormSubmittedSnackbarVisible2.value = true
          textSnackbar.value = error
          colorSnackbar.value = 'error'
        })
    }

    return {
      dialogForm,
      handleFormSubmit,

      isPasswordVisible,
      usuario,
      password,
      errorMessages,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiCloseThick,
      },
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logoLogin,

      // Template Refs
      loginForm,
      sucursal,
      listsucursal,
      sucursalActive,

      isFormSubmittedSnackbarVisible2,
      colorSnackbar,
      textSnackbar,
      loading,
      carga,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
.color-head-card {
  // background-color: rgb(20 20 20 / 25%);
  background: #56ccf2; /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #2f80ed, #56ccf2); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #2f80ed,
    #56ccf2
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.color-card-text {
  color: #156bb6;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
